<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.62868 1.5848L7.24265 2.10812C7.13235 2.14895 7.05882 2.25658 7.05882 2.37535C7.05882 2.49412 7.13235 2.60175 7.24265 2.64257L8.62868 3.16589L9.14706 4.56512C9.1875 4.67647 9.29412 4.7507 9.41177 4.7507C9.52941 4.7507 9.63603 4.67647 9.67647 4.56512L10.1949 3.16589L11.5809 2.64257C11.6912 2.60175 11.7647 2.49412 11.7647 2.37535C11.7647 2.25658 11.6912 2.14895 11.5809 2.10812L10.1949 1.5848L9.67647 0.185574C9.63603 0.0742296 9.52941 0 9.41177 0C9.29412 0 9.1875 0.0742296 9.14706 0.185574L8.62868 1.5848ZM1.69485 14.6752C1.00735 15.3692 1.00735 16.4975 1.69485 17.1953L2.96691 18.4795C3.65441 19.1735 4.77206 19.1735 5.46324 18.4795L19.4816 4.32388C20.1691 3.62983 20.1691 2.50154 19.4816 1.80378L18.2096 0.523319C17.5221 -0.170728 16.4044 -0.170728 15.7132 0.523319L1.69485 14.6752ZM17.8162 3.06568L13.9559 6.96274L13.0993 6.09796L16.9596 2.20091L17.8162 3.06568ZM0.275735 4.34986C0.110294 4.41295 0 4.57255 0 4.7507C0 4.92885 0.110294 5.08844 0.275735 5.15154L2.35294 5.93837L3.13235 8.03536C3.19485 8.20237 3.35294 8.31372 3.52941 8.31372C3.70588 8.31372 3.86397 8.20237 3.92647 8.03536L4.70588 5.93837L6.78309 5.15154C6.94853 5.08844 7.05882 4.92885 7.05882 4.7507C7.05882 4.57255 6.94853 4.41295 6.78309 4.34986L4.70588 3.56302L3.92647 1.46604C3.86397 1.29902 3.70588 1.18767 3.52941 1.18767C3.35294 1.18767 3.19485 1.29902 3.13235 1.46604L2.35294 3.56302L0.275735 4.34986ZM13.2169 13.8512C13.0515 13.9143 12.9412 14.0739 12.9412 14.2521C12.9412 14.4302 13.0515 14.5898 13.2169 14.6529L15.2941 15.4398L16.0735 17.5367C16.136 17.7038 16.2941 17.8151 16.4706 17.8151C16.6471 17.8151 16.8051 17.7038 16.8676 17.5367L17.6471 15.4398L19.7243 14.6529C19.8897 14.5898 20 14.4302 20 14.2521C20 14.0739 19.8897 13.9143 19.7243 13.8512L17.6471 13.0644L16.8676 10.9674C16.8051 10.8004 16.6471 10.6891 16.4706 10.6891C16.2941 10.6891 16.136 10.8004 16.0735 10.9674L15.2941 13.0644L13.2169 13.8512Z" :fill="iconColor"/>
  </svg>

</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconMagicPen",
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 19,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
